<script setup lang="ts">
import type { Media_Plain } from '@webamboos/admin'
import { type VariantProps, cva } from 'class-variance-authority'

const style = cva([], {
  variants: {
    imageVariant: {
      grayscale: 'grayscale',
      normal: 'grayscale-0',
    },
  },
})
type StyleProps = VariantProps<typeof style>

withDefaults(
  defineProps<{
    imageVariant?: StyleProps['imageVariant']
    person: {
      name: string
      image?: Media_Plain
      role?: string
      partnerName?: string
    }
  }>(),
  {
    imageVariant: 'normal',
  },
)
</script>

<template>
  <div class="flex items-center">
    <NuxtImg
      v-if="person.image"
      loading="lazy"
      format="webp"
      class="mr-4 h-12 w-12 rounded-full"
      :class="style({ imageVariant })"
      :src="person.image.url"
      :alt="person.image.alternativeText"
      :width="64"
      :height="64"
    />

    <div class="font-medium dark:text-white">
      <Heading size="small">{{ person.name }}</Heading>
      <Paragraph size="normal" weight="normal" class="!tracking-tight !text-gray-500">
        {{
          person.partnerName
            ? `${person.role || ''} ${$t('projects.testimonial_at')} ${person.partnerName}`
            : person.role
        }}
      </Paragraph>
    </div>
  </div>
</template>
