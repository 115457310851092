<script setup lang="ts">
import type { Testimonial_Plain } from '@webamboos/admin'

const props = defineProps<{
  testimonial: Testimonial_Plain
}>()

const person = {
  name: props.testimonial.person,
  image: props.testimonial.image,
  role: props.testimonial.role,
  partnerName: props.testimonial?.partner?.name,
}
</script>

<template>
  <div class="p-2 bg-white rounded-lg bordershadow-input-light">
    <div class="p-6 bg-white rounded bordershadow-gray">
      <Paragraph size="medium" weight="normal" class="!text-gray-600 mb-8">
        {{ testimonial.message }}
      </Paragraph>

      <Person image-variant="grayscale" :person="person" />
    </div>
  </div>
</template>
